import React from "react";
import OCRForm from "./components/OCRForm";
import "./App.css";

const App: React.FC = () => {
	return (
		<div className="App">
			<div className="container">
				<h1 style={{paddingBottom: 16}}>Sampras OCR Receipt API Prototype</h1>
			</div>
			<OCRForm />
		</div>
	);
};

export default App;
