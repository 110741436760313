import React, { useState } from "react";
import { Form, Input, Button, Select, Checkbox, Upload, Image, Popconfirm, Slider, message, List, Spin } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import type { GetProp, UploadFile, UploadProps } from "antd";
import type { RcFile } from "antd/es/upload/interface";
import imageCompression from "browser-image-compression";

const API_URL = process.env.REACT_APP_API_URL ?? "";
const { Option } = Select;

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

const versionRangeDefault = [2, 5];
const getBase64 = (file: FileType): Promise<string> =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result as string);
		reader.onerror = (error) => reject(error);
	});

const OCRForm: React.FC = () => {
	const params = new URLSearchParams(window.location.search);
	const [token, setToken] = useState<string>(params.get("token") || "54c54e4f-901d-4186-9aa1-9d3b064bc3ba");
	const [loading, setLoading] = React.useState<boolean>(false);

	const [versionOptions, setVersionOptions] = useState<string[]>(["v2.1", "v2.2", "v2.3", "v2.4", "v2.5", "v2.6"]);
	const [versionOptionsStorage, setVersionOptionsStorage] = useState<string[]>([]);
	const [versionRange, setVersionRange] = useState<number[]>([2.1, 2.6]);
	const [result, setResult] = useState<any>(null);
	const [previewOpen, setPreviewOpen] = useState(false);
	const [previewImage, setPreviewImage] = useState("");
	const [fileList, setFileList] = useState<UploadFile[]>([]);
	const [links, setLinks] = useState<string[]>(() => {
		const storedLinks = localStorage.getItem("log");
		return storedLinks ? JSON.parse(storedLinks) : [];
	});

	const handleSubmit = async (values: any) => {
		setLoading(true);
		const { version, compress, customerId } = values;

		// if (fileList.length !== 2) {
		// 	message.error("Please upload exactly two files");
		// 	return;
		// }

		const fd = new FormData();
		fd.append("need_zip", compress.toString());
		fd.append("customId", customerId);

		// Append files as individual items
		// await Promise.all(
		// 	fileList.map(async (file, index) => {
		// 		const base64String = await fileToBase64(file.originFileObj as RcFile);
		// 		fd.append(`imageFile[${index}]`, base64String);
		// 	})
		// );
		await Promise.all(
			fileList.map(async (file, index) => {
				let fileToUpload = file.originFileObj as RcFile;

				if (compress) {
					const options = {
						maxSizeMB: 1,
						maxWidthOrHeight: 1500,
						useWebWorker: true,
					};

					try {
						const compressedFile = await imageCompression(fileToUpload, options);
						fileToUpload = new File([compressedFile], fileToUpload.name, {
							type: compressedFile.type,
							lastModified: compressedFile.lastModified,
						}) as RcFile;
					} catch (error) {
						console.error("Error compressing image:", error);
					}
				}

				const base64String = await fileToBase64(fileToUpload);
				fd.append(`imageFile[${index}]`, base64String);
			})
		);

		// Append receiptType as individual items
		const ver = version.slice(1);
		const receiptTypes = parseFloat(ver) >= 2.5 ? ["cardFront", "cardBack"] : ["shop", "slip"];
		receiptTypes.forEach((type, index) => {
			fd.append(`receiptType[${index}]`, type);
		});

		// Array.from(fd.entries()).map((entry, index) => {
		// 	console.log(`${index}: ${entry}`);
		// });

		try {
			const headers = {
				mode: "no-cors",
				Accept: "application/json",
				"sampras-api-key": token,
			};
			const response = await fetch(`${API_URL}/api/${version}/process`, {
				method: "POST",
				headers,
				body: fd,
			});

			const data = await response.json();
			setResult(data);
			handleResponseData(data, version);
			setLoading(false);
		} catch (error) {
			console.error("Error:", error);
			setResult({ error: "An error occurred" });
			setLoading(false);
		}
	};

	const handleResponseData = (data: { data: { token: string } }, version: String) => {
		if (data.data && data.data.token) {
			const newLink = { id: data.data.token, token: token, ver: version };
			const db = JSON.parse(localStorage.getItem("log") || "[]");
			const updatedDb = [newLink, ...db];
			localStorage.setItem("log", JSON.stringify(updatedDb));
			setLinks(updatedDb);
		}
	};

	const getResult = async (
		e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
		id: string,
		token: string,
		version: string
	) => {
		e.preventDefault();
		setLoading(true);

		try {
			const headers = {
				mode: "no-cors",
				Accept: "application/json",
				"sampras-api-key": token,
			};
			console.log(token);
			const response = await fetch(`${API_URL}/api/${version}/result/${id}`, {
				method: "GET",
				headers,
			});
			if (!response.ok) {
				throw new Error("Network response was not ok");
			}

			const data = await response.json();
			setResult(data);
		} catch (error: any) {
			console.error("Error:", error);
			message.error(error?.message);
		}
		setLoading(false);
	};

	const handlePreview = async (file: UploadFile) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj as FileType);
		}

		setPreviewImage(file.url || (file.preview as string));
		setPreviewOpen(true);
	};

	const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
		setFileList(newFileList);
	};

	const uploadButton = (
		<button style={{ border: 0, background: "none", cursor: "pointer" }} type="button">
			<PlusOutlined />
			<div style={{ marginTop: 8 }}>Upload</div>
		</button>
	);

	const handleSliderChange = (value: number[]) => {
		setVersionRange(value);
		const newVersionOptions: string[] = [];
		for (let i = value[0]; i <= value[1]; i = Math.round((i + 0.1) * 10) / 10) {
			newVersionOptions.push(`v${i.toFixed(1)}`);
		}
		setVersionOptionsStorage(newVersionOptions);
	};

	const confirm = () => {
		setVersionOptions(versionOptionsStorage);
	};

	const fileToBase64 = (file: File): Promise<string> => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = () => {
				const base64String = reader.result as string;
				resolve(base64String.split(",")[1]);
			};
			reader.onerror = (error) => {
				reject(error);
			};
			reader.readAsDataURL(file);
		});
	};

	return (
		<Spin spinning={loading} tip={<h4>Loading...</h4>} delay={500} size="large">
			<div className="container">
				<div className="form-container">
					<Form layout="vertical" onFinish={handleSubmit} initialValues={{ token: token, compress: true }}>
						<Form.Item
							label="Sampras-OCR Token"
							name="token"
							rules={[{ required: true, message: "Please input OCR Token!" }]}>
							<Input onChange={(e) => setToken(e.target.value)} />
						</Form.Item>
						<div>
							<Form.Item
								label="Version"
								name="version"
								initialValue={versionOptions[versionOptions.length - 1]}
								style={{ marginBottom: 8 }}>
								<Select>
									{versionOptions.map((version) => (
										<Option key={version} value={version}>
											{version}
										</Option>
									))}
								</Select>
							</Form.Item>
							<Popconfirm
								title="Increase current version limit"
								description={
									<>
										<Slider
											range
											value={versionRange}
											min={versionRangeDefault[0]}
											max={versionRangeDefault[1]}
											step={0.1}
											style={{ minWidth: 300 }}
											onChange={handleSliderChange}
										/>
									</>
								}
								onConfirm={confirm}>
								<Button type="primary" style={{ marginBottom: 24 }}>
									Increase current version limit
								</Button>
							</Popconfirm>
						</div>
						<Form.Item label="Compress" name="compress" valuePropName="checked">
							<Checkbox />
						</Form.Item>
						<Form.Item
							label="Customer ID"
							name="customerId"
							rules={[{ required: true, message: "Please input Customer ID!" }]}>
							<Input />
						</Form.Item>
						<Form.Item
							label={"Upload (" + fileList.length + "/2)"}
							name="image"
							valuePropName="image"
							rules={[
								{
									required: true,
									validator: (_, value) => {
										if (value?.fileList.length < 1) {
											return Promise.reject("Please upload exactly 1 files!");
										}
										return Promise.resolve();
									},
									message: "Please upload exactly 1 files!",
								},
							]}>
							<Upload
								listType="picture-card"
								accept="image/*"
								onPreview={handlePreview}
								maxCount={2}
								beforeUpload={() => false}
								onChange={handleChange}
								multiple>
								{fileList.length === 2 ? null : uploadButton}
							</Upload>
						</Form.Item>
						<Form.Item>
							<Button type="primary" htmlType="submit">
								Submit
							</Button>
						</Form.Item>
					</Form>
					{previewImage && (
						<Image
							wrapperStyle={{ display: "none" }}
							preview={{
								visible: previewOpen,
								onVisibleChange: (visible) => setPreviewOpen(visible),
								afterOpenChange: (visible) => !visible && setPreviewImage(""),
							}}
							src={previewImage}
						/>
					)}
				</div>
				<div className="result-container">
					<div>
						<h3 style={{ marginTop: 0 }}>Result:</h3>
						<div style={{ height: 280, overflowY: "auto", backgroundColor: "#E8E8E8" }}>
							{result && <pre style={{ marginTop: 0, padding: "8px 16px" }}>{JSON.stringify(result, null, 2)}</pre>}
						</div>
					</div>
					<div>
						<List
							header={<h3 style={{ margin: 0 }}>Log</h3>}
							bordered
							dataSource={links}
							renderItem={(item: any) => (
								<List.Item>
									<a
										href={`/token/${item.id}?apiToken=${item.token}&version=${item.version}`}
										onClick={(event) => getResult(event, item.id, item.token, item.ver)}>
										{item.id} {item.ver}
									</a>
								</List.Item>
							)}
						/>
					</div>
				</div>
			</div>
		</Spin>
	);
};

export default OCRForm;
